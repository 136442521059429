<template>
  <div class="v-toolbar">
    <slot></slot>
  </div>
</template>

<style scoped>
.v-toolbar {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  user-select: none;

  width: 100%;
  height: 70px;
  padding: 8px 16px;
  color: var(--text-sub-color);
  margin: 0 auto;
  box-shadow: 0px 1px 5px -1px rgb(0 0 0 / 16%);
  font-size: 24px;
}
</style>
